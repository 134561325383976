const setDateTime = (data) => {
  if (!data) return null;

  // Parse the backend date (add time explicitly to avoid UTC interpretation issues)
  const backendDate = new Date(`${data}T00:00:00`);

  // Get the user's system current date and yesterday's date
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  // Normalize backend date to midnight (same approach for comparison)
  backendDate.setHours(0, 0, 0, 0);

  // Determine the formatted string
  let formattedDate = "";
  if (backendDate.getTime() === today.getTime()) {
    formattedDate = "Today";
  } else if (backendDate.getTime() === yesterday.getTime()) {
    formattedDate = "Yesterday";
  } else {
    formattedDate = backendDate.toLocaleDateString("en-US", {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  }

  return formattedDate;
};

export default setDateTime;
