import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  setActiveTab,
  resendVerifyEmailAsync,
  setUserResumes,
  setUserOTPEmail,
} from "../../../../redux/slices/AuthSlice";
import { emailRegex } from "../../../../constants";
import { getResumeForApplyingOnJob } from "../../../../redux/slices/candidateDetailSlice";
import { getUserTimezone } from "../../../../utils/getUserTimezone";
import API from "../../../../api";
import ActionModal from "../../../Common/ActionModal/ActionModal";
import "./LoginForm.css";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [locationValidModel, setLocationValidModel] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  const getResumes = async (token) => {
    dispatch(getResumeForApplyingOnJob({ token: token }))
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailRegex, "Please enter valid email address")
        .required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email, password } = data;
      try {
        // Get the user's timezone
        const userTimezone = getUserTimezone();

        const formData = new FormData();
        formData.append("email", email.trim());
        formData.append("password", password.trim());
        formData.append("timezone", userTimezone);
        setLoader(true);

        await API.post(`/auth/login`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const { location_valid, valid_location } = response?.data;

              if (location_valid === false) {
                setDomainUrl(valid_location);
                setLocationValidModel(true);
                setLoader(false);
                return;
              }

              if (res?.result === true) {
                let successMsg = res?.message?.success;
                toast.success(successMsg, {
                  autoClose: 5000,
                  closeOnClick: false,
                });
                dispatch(setUserOTPEmail(email.trim()));              
                navigate("/login/verify-otp");                         
              } else {
                let errorMsg = res?.message?.error;
                if (errorMsg) {
                  setErrorMsg(errorMsg);
                  setTimeout(() => {
                    setErrorMsg("");
                  }, 5000);
                }
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    } else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const locationValidModelHandler = (value) => {
    setLocationValidModel(value);
  };

  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row">
            <div className="candiate-login-form">
              <div className="candidate-signin">
                <h2>Sign in</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`candidate-username ${
                      isFormFieldValid("email") && "error-input"
                    }`}
                  >
                    <label
                      for="email-box"
                      className="visibility-hidden opacity-0 position-absolute"
                    >
                      Email
                    </label>
                    <input
                      className="form-font-f"
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="email-box"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("email")}
                  </div>

                  <div className="candidate-password">
                    <div
                      className={`signup-passowrd  ${
                        isFormFieldValid("password") && "error-input"
                      }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <label
                          for="password-field"
                          className="visibility-hidden opacity-0 position-absolute"
                        >
                          Password
                        </label>
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="icon-hide">
                          <>
                            <img
                              src="/image/hide-password.png"
                              alt=""
                              className={passwordShown && "password-icon-togle"}
                              onClick={() => setPasswordShown(true)}
                            />
                            <img
                              src="/image/password.png"
                              alt=""
                              className={
                                !passwordShown && "password-icon-togle"
                              }
                              onClick={() => setPasswordShown(false)}
                            />
                          </>
                        </div>
                      </div>
                      {getFormErrorMessage("password")}
                    </div>

                    <div className="remember-forget-password">
                      {/* <input
                        id="rememberme"
                        name="rememberme"
                        value="remember"
                        type="checkbox"
                      />{" "}
                      &nbsp;Remember me * */}
                      <Link to="/forgot-password">
                        <p className="forget-password">Forgot password?</p>
                      </Link>
                    </div>
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="form-submit">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Sign in"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="signup-here">
                    <p>
                      Don’t have an account?{" "}
                      <Link to="/signup" onClick={() => onSignUpHandler()}>
                        <span>Sign up</span>
                      </Link>
                    </p>
                  </div>
                  <div className="or-divider">
                    <div className="divider-one"></div>
                    <div className="text-or">Or</div>
                    <div className="divider-one"></div>
                  </div>
                  <div className="signup-here">
                    <p>
                      Haven't received the confirmation email yet?{" "}
                      <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                        <span>Resend Email</span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>     
      {locationValidModel && (
        <ActionModal
          modalArt="/image/warning-wishlist.png"
          heading="Invalid location"
          content={`Your account does not exist on this domain. Please login to your local domain`}
          closeModal={locationValidModelHandler}
        >
          <div className="d-flex justify-content-center w-100">
            <a
              href={domainUrl === "" ? "#" : domainUrl}
              className="domain-url-login"
            >
              {domainUrl}
            </a>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default LoginForm;
